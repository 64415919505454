@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@media only screen and (min-width: 700px) {
  html,
  #root
   {
      background-image: url(../assets/images/background.png) !important;
      background-attachment: fixed;
      background-position: center;
      background-size: cover;
  }
}
@media only screen and (max-width: 700px) {
  html,
  #root
   {
      background-color:#000000 ;
      background-image: url(../assets/images/mobilBg.png) !important;
      background-attachment: fixed;
      background-position: center;
      background-size: cover;
  }
}
/* Add Font */
 @font-face {
    font-family: pirulen;
    src: url(../assets/fonts/pirulen\ rg.otf);
  }
 * {
    font-family: pirulen;
 }

 @font-face {
  font-family: Gotham;
  src: url(../assets/fonts/KD\ fontlar/Gotham-Bold.otf);
}
/* Add Font */

::-webkit-scrollbar{
  width: 10px;
  background: linear-gradient(#06b8a354, #020202 60%);
  border-radius: 5px 5px 5px 5px;
}
::-webkit-scrollbar-thumb{
  background: url(../assets/images/scroll.png) no-repeat;
  background-position: top;
} 

.chakra-ui-light{
  margin: 0; 
  height: 100%;
}
.shadow:hover {
  filter: drop-shadow(0 0 10px #00FFE2);
  transition: filter 0.1s ease-in-out 0s;
  /* Similar syntax to box-shadow */
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.3;
}
.overflow{
  overflow-y: scroll;
  overflow-x: hidden ;
}

.css-v9b9hc{
pointer-events:none;
}

.font-gotham{
font-family: Gotham !important;
}

.font-gotham{
  font-family: Gotham !important;
}


.btn3 {
position: fixed;
left: 0; 
right: 0; 
margin-left: auto; 
margin-right: auto; 
width: 221px;
height: 226px;
background: transparent;
background-size: 400%;
border-radius: 30px;
z-index: 1;
}
@media only screen and (max-width: 580px) {
.btn3 {
  position: fixed;
left: 0; 
right: 0; 
margin-left: auto; 
margin-right: auto; 
  width: 121px;
  height: 126px;
  background: transparent;
  background-size: 400%;
  border-radius: 30px;
  z-index: 1;
}
}
.btn2 {
animation: animate 8s linear infinite;
}

@keyframes animate {
0% {
  background-position: 0%;
}
100% {
  background-position: 400%;
}
}

.btn2:before {
content: "";
position: absolute;
top: -5px;
right: -5px;
bottom: -5px;
left: -5px;
z-index: -1;
background: linear-gradient(90deg, #ffffff, #01C0AA, #269E8B, #ffffff);
background-size: 400%;
border-radius: 40px;
opacity: 0;
transition: .5s;
}

.btn2:before {
filter: blur(20px);
opacity: 1;
animation: animate 8s linear infinite;
}

/* rigt chat icon */


.btn1 {
position: fixed;
right:50px;
bottom: 50px;
width: 131px;
height: 136px;
background: transparent;
background-size: 400%;
border-radius: 30px;
z-index: 1;
}
@media only screen and (max-width: 580px) {
.btn1 {
  position: fixed;
  right:50px;
  bottom: 50px;
  width: 71px;
  height: 76px;
  background: transparent;
  background-size: 400%;
  border-radius: 30px;
  z-index: 1;
}
}
.btn {
animation: animate 8s linear infinite;
}

@keyframes animate {
0% {
  background-position: 0%;
}
100% {
  background-position: 400%;
}
}

.btn:before {
content: "";
position: absolute;
top: -5px;
right: -5px;
bottom: -5px;
left: -5px;
z-index: -1;
background: linear-gradient(90deg, #ffffff, #01C0AA, #269E8B, #ffffff);
background-size: 400%;
border-radius: 40px;
opacity: 0;
transition: .5s;
}

.btn:before {
filter: blur(20px);
opacity: 1;
animation: animate 8s linear infinite;
}

.progress {
display: inline-block;
width: 90%;
height: 50px;
margin: 35px;
margin-top: 90px;
border-radius: 20px;
background: #00FFE2;
}

.bar {
border-radius: 20px;
width: 0%;
height: 100%;
transition: width;
transition-duration: 1s;
transition-timing-function: cubic-bezier(.36,.55,.63,.48);
}

.bars {
background-image: url(../assets/images/progresbar.png);
}

.chakra-checkbox__control{
background-color: #00FFD4;
}

/* Phone Input */
.react-tel-input .form-control {
height: 45px !important;
background: transparent !important;
border: none !important;
color: white;
font-size: 25px !important;
}
.react-tel-input .flag-dropdown{
background: transparent !important;
border: none !important;
font-size: 25px !important;
}
.react-tel-input .selected-flag:hover{
background: transparent !important;

}
.country-list li{
font-size: 15px;
}

@media only screen and (max-width: 1000px) {
.country-list li{
  font-size: 12px;
}
.react-tel-input .form-control {
  height: 45px !important;
  background: transparent !important;
  border: none !important;
  color: white;
  font-size: 20px !important;
}
}
/* footer */

@media only screen and (min-width: 1950px) {
#footer
 {
    position: fixed;
}
}

.selected{
background-color: #166d61 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  color: #ffffff;  
  filter: invert(0.8);
}

.chakra-image{
  border-radius: 4%;
}

.chakra-image:hover{
  cursor: pointer;
}

.css-mjjbhi:hover{
  cursor: pointer;
}

.slick-slide{
  scale: .9;
}

.slick-center{
  scale: 1;
}

.slick-center .css-mjjbhi{
  background-color: #166d61 !important;
}

.css-155za0w{
  position: relative;
}

.prevArrow, .prevNext{
  position: absolute;
  font-size: 32px;
  color: white;
  transition: all .3s ease-in-out;
}

.prevArrow{
  left: 5%;
  top: 44%;
}
.prevNext{
  right: 5%;
  top: 44%;
}

.prevNext:hover, .prevArrow:hover{
  scale: 1.2;
}

